.root {
  margin: 0 20px 0 10px;
  flex: 4;
  div {
    margin-bottom: 50px;
    display: grid;
    grid-template-columns: 20% 20% 20% 20%;
    :hover{
      cursor: pointer;
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5); 
    }
  }
}
