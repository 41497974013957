@import '../../../../styles/variables';

.root{
    p{
        margin-top: 7%;
        font-size: large;
    }
    margin: 30px 20px 0 10px;
    text-align: center;
    width: 50%;
    .carousel{
        position: relative;
        text-align: center;
    }
    .back_button{
        position: absolute;
        background-color: transparent;
        top: 25%;
        left: 10%;
        border: none;
        color: $dark_blue;
        :hover {
            color: $teal,
        }
    }
    .next_button{
        position: absolute;
        top: 25%;
        right: 10%;
        background-color: transparent;
        border: none;
        color: $dark_blue;
        :hover {
          color: $teal,
        }
    }
}