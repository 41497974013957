@import '../../../styles/variables';

.root {
    margin: 0 20px 0 10px;
    span{
        color: $teal
    }
    li:hover{
        cursor: pointer;
    }
    .active {
        padding: 5px;
        margin: 5px;
        cursor: pointer;
        display: flex;
        align-items: center;
        border-radius: 10px;
        text-decoration: none;
        background-color: $dark_blue;
        color: rgb(251, 251, 255);
      }
      
      .inactive{
        padding: 5px;
        margin: 5px;
        cursor: pointer;
        display: flex;
        align-items: center;
        border-radius: 10px;
        text-decoration: none;
        color: $black
      }
      
}