@import "../../styles/variables";

.sidebar {
  flex: 1;
  height: calc(100vh - 50px);
  top: 50px;
  background-color: rgb(251, 251, 255);

  .sidebarWrapper {
    padding: 20px;
  }

  .sidebarMenu {
    margin-bottom: 10px;
  }
  .sidebarTitle {
    font-size: 13px;
  }

  .sidebarList {
    list-style: none;
    padding: 5px;
  }

  .active {
    padding: 5px;
    margin: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 10px;
    text-decoration: none;
    background-color: $dark_blue;
    color: rgb(251, 251, 255);
  }

  .inactive{
    padding: 5px;
    margin: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 10px;
    text-decoration: none;
    color: $black
  }
  .sidebarIcon {
    margin-right: 5px;
    font-size: 20px;
  }

}
