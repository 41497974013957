@import "../../styles/variables";

.root {
  background-color: $dark_blue;
  height: 50px;
  color: $white;
  .headerWrapper {
    height: 100%;
    width: 100%;
    display: flex;
    padding: 0px 20px;
    align-items: center;
    justify-content: space-between;
  }
  .leftSide {
    display: flex;
    align-items: center;
  }
  amplify-s3-image {
    --width: 50px;
    --height: 50px;
  }
  .pointer:hover {
    cursor: pointer;
  }
}
