@import "../../../styles/variables";

.root {
  margin: 0 20px 0 10px;
  flex: 4;
  ul{
    margin: 0;
  }
  ul li {
    border: none;
    display: flex;
    color: $teal;
  }
  .header {
    display: flex;
    justify-content: space-evenly;
    border-bottom: 1px solid $teal;
  }
  span {
    color: $teal;
  }
  .playlists {
    display: flex;
    margin-top: 10px;
  }
}