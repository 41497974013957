@import '../../styles/variables';

.root { 
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    form{
        display: flex;
        flex-direction: column;
        position  : absolute;
        text-align: center;
        padding: 20px;
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5); 
    }
    img{
        align-self: center;
    }
    .inputs{
        margin-bottom: 10px;
        color: $white
    }
    button{
        background-color: $dark_blue;
        :hover{
            background-color: $dark_blue;
        }
    }
}