@import "../../styles/variables";

.root {
  width: 100%;
  margin: 10px;
  .some {
    margin: 10px;
    amplify-s3-image {
      --width: 70px;
      --height: 70px;
    }
    .editIcon {
      margin-left: 5px;
      font-size: 20px;
    }
    .infoItem {
      margin: 20px 20px 0 0;
      padding: 5px 30px 30px 5px;
      border-radius: 10px;
      -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
      box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    }
    .textField {
      width: 100%;
      height: 100%;
    }
    .infoContainer {
      display: flex;
      flex-direction: column;
    }
    .buttons{
      display: flex;
      align-self: center;
    }
    .saveButton {
      width: 20%;
      margin-top: 10px;
      align-self: center;
      background-color: $teal;
      color: $white;
    }
    .cancelButton {
      width: 20%;
      margin: 10px 0 0 10px;
      align-self: center;
      background-color: $light-red;
      color: $white;
    }
    .leftSide {
      float: left;
      width: 50%;
      display: flex;
      flex-direction: column;
    }
    span {
      display: flex;
    }
    span:nth-of-type(2) {
      margin-top: 10px;
    }
    .rightSide {
      margin: 10px;
      display: flex;
      flex-direction: column;
      .contact {
        font-weight: bolder;
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        p {
          padding-left: 10px;
          margin: 0;
        }
      }
      .contact:last-of-type() {
        border-bottom: none;
      }
      p {
        margin: 0;
      }
    }
  }
}
