@import '../../styles/variables';

.featured {
  display: flex;
  flex: 4;
  justify-content: space-between;
  margin: 10px;
  height: fit-content;
  :hover{
    background-color: $teal;
  }

  .featuredItem {
    flex: 1;
    margin: 0px 20px;
    padding: 30px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  }

  .featuredTitle {
    font-size: 20px;
  }

  .featuredContainer {
    margin: 10px 0px;
    display: flex;
    align-items: center;
  }
  .featuredNumber {
    font-size: 30px;
    font-weight: 600;
  }

  .featuredDetails {
    float: right;
  }

  .featuredLink {
    display: flex;
    text-decoration: none;
  }

  .featuredIcon {
    margin-left: 5px;
    font-size: 20px;
  }
}
