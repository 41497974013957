@import "../../styles/variables";

.root {
  margin: 0 20px 0 10px;
  flex: 4;
  .table_head {
    background-color: $dark_blue;
  }
  .tableCell {
    color: $white;
    width: 15%;
  }
  .tableRow:hover {
    background-color: $teal;
    cursor: pointer;
  }
}
