@import "../../styles/variables";

.root {
  background-color: $teal;
  margin: auto;
  height: 600px;
  width: 600px;
  position: relative;
  a {
    text-decoration: none;
    color: $white;
  }
}

.err {
  color: $white;
  font-family: "Nunito Sans", sans-serif;
  font-size: 11rem;
  position: absolute;
  left: 20%;
  top: 8%;
}

.msg {
  text-align: center;
  font-family: "Nunito Sans", sans-serif;
  font-size: 1.6rem;
  position: absolute;
  left: 16%;
  top: 45%;
  width: 75%;
}
